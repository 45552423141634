import { hexToBN } from "../../utils/utils";
import { debug } from "../../utils/debugger";
import { apiUrl } from "../../api";
import { readStorage } from "../CarmineStaking/calls";
import { QueryFunctionContext } from "react-query";

export type Eligible = {
  eligible: true;
  claimable: bigint;
  claimed: bigint;
  proof: string[];
};

type NotEligible = {
  eligible: false;
};

export type ProofResult = Eligible | NotEligible;

export const getProof = async (address: string): Promise<ProofResult> => {
  const merkleTreeRequest = fetch(apiUrl(`airdrop?address=${address}`)).then(
    (r) => r.json()
  );

  const claimedPromise = readStorage(address);

  const [merkleTreeResponse, claimed] = await Promise.all([
    merkleTreeRequest,
    claimedPromise,
  ]);

  if (merkleTreeResponse.status !== "success") {
    return { eligible: false };
  }

  const total = hexToBN(merkleTreeResponse.data[1]);
  const diff = total - claimed;
  const claimable = diff < 100n ? 0n : total - claimed;

  debug("CARM token claim data:", {
    total: total.toString(10),
    claimed: claimed.toString(10),
    claimable,
  });

  return {
    eligible: true,
    proof: merkleTreeResponse.data, // proof generated by backend
    claimable, // how much can be claimed
    claimed: claimed, // how much has been claimed
  };
};

export const getAirdropDataQuery = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<ProofResult> => {
  const address = queryKey[1];
  return getProof(address);
};
